export function paramsToUrlData(params) {
  let urlData = '';

  Object.entries(params).forEach(([key, value]) => {
    if (value === null || value === '' || value.length <= 0) {
      return;
    }

    let preparedValue = value;

    if (Array.isArray(preparedValue)) {
      if (typeof preparedValue === 'object') {
        preparedValue = preparedValue.map(element => element.code).join(',');
      } else {
        preparedValue = preparedValue.join(',');
      }
    }

    if (typeof preparedValue === 'object') {
      preparedValue = preparedValue.code;
    }

    urlData += `${key}/${preparedValue}/`;
  });

  return urlData;
}

export function getParamValue(params, paramName) {
  let paramValue = null;
  const sortByIndex = params?.data?.findIndex(param => param === paramName);
  if (sortByIndex !== null && sortByIndex !== undefined && sortByIndex !== -1) {
    if ((sortByIndex + 1) in params.data) {
      paramValue = decodeURIComponent(params.data[sortByIndex + 1]);
    }
  }

  return paramValue;
}
